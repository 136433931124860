<template>
  <div>
    <RightSideBar
      v-if="isOpen"
      close-button
      @close="isOpen = false"
      :loading="true"
    >
      <template v-slot:title>
        <p class="text-darkPurple">Update Redeployment</p>
      </template>
    </RightSideBar>
    <template>
      <Redeployment
      ref="redeployment"
      title="Update Redeployment"
      @redeployed="$emit('redeployed', $event)"
    />
    </template>
  </div>
</template>

<script>
export default {
  components: {
    RightSideBar: () => import("@/components/RightSideBar"),
    Redeployment: () => import('./redeployment'),
  },
  data(){
    return {
      isOpen: false,
    }
  },
  methods: {
    toggle(payload){
      this.isOpen = true

      const redeployment = {
        ...payload.newDetails.employee,
        payrollReview: JSON.stringify(payload.newDetails.employee.payrollReview),
        relocationAllowance: JSON.stringify(payload.newDetails.employee.relocationAllowance),
      }

      this.$_getEmployeePayInfo(payload.userId).then(({ data }) => {
        const employeeInfo = {...payload, payInfo: data.payInfo}
        this.$refs.redeployment.toggle({ employeeInfo, redeployment })
        this.isOpen = false
      }).catch(() => {
        const employeeInfo = {...payload, payInfo: {}}
        this.$refs.redeployment.toggle({ employeeInfo, redeployment })
        this.isOpen = false
      })
    },
  }
}
</script>

<style>
.rightSidebar {
  width: 520px !important;
}
</style>
